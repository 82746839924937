import React from 'react';
import PetExpenseImage1 from "../../../assets/images/pet_risk_1.png";
import PetExpenseImage2 from "../../../assets/images/pet_risk_2.png";
import PetExpenseImage3 from "../../../assets/images/pet_risk_3.png";
import PetExpenseImage4 from "../../../assets/images/pet_risk_4.png";
import PetExpenseImage5 from "../../../assets/images/pet_risk_5.png";
import PetExclusiveImage from "../../../assets/images/pet-exclusive.png";
import PetConverageNote from "../../../assets/images/pet-coverage-note.png";
// import { lst_pet_decline_home } from '../util';

function PetConverage(props) {
    return (
        <>
            <section className="container">
                <div 
                    className="title_section" 
                    data-descr="Phạm vi bảo hiểm" 
                    style={{ color: '#564741' }}
                >
                    <span>
                        Phạm vi bảo hiểm
                    </span>
                </div>
                {covData.map((item, index) => (
                    <>
                        <div 
                            className="row" 
                            style={{ 
                                marginTop: '32px', 
                                marginBottom: '32px' 
                            }}
                        >
                            {item.direction === 'left' ? (
                                <>
                                    <div className='col-md-1'></div>
                                    <div 
                                        className='col-md-3' 
                                        style={{ textAlign: 'right' }}
                                    >
                                        <img 
                                            alt="Pet Expense" 
                                            src={item.image} 
                                            style={{ 
                                                alignItems: 'center', 
                                                textAlign: 'right' 
                                            }} 
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <h5>
                                            {item.title}
                                        </h5>
                                        <p style={{ textAlign: 'justify' }}>
                                            {item.content}
                                        </p>
                                    </div>
                                    <div className='col-md-1'></div>
                                </>
                            ) : (
                                <>
                                    <div className='col-md-3'></div>
                                    <div 
                                        className='col-md-6' 
                                        style={{ 
                                            alignItems: 'center', 
                                            textAlign: 'left', 
                                            paddingLeft: '32px' 
                                        }}
                                    >
                                        <h5>
                                            {item.title}
                                        </h5>
                                        <p style={{ textAlign: 'justify' }}>
                                            {item.content}
                                        </p>
                                    </div>
                                    <div 
                                        className='col-md-2' 
                                        style={{ textAlign: 'left' }}
                                    >
                                        <img 
                                            alt="Pet Expense" 
                                            src={item.image} 
                                            style={{ 
                                                alignItems: 'center', 
                                                textAlign: 'left' 
                                            }} 
                                        />
                                    </div>
                                    <div className='col-md-1'></div>
                                </>
                            )}
                        </div>
                    </>
                ))}
                <div className='row' style={{marginLeft: '190px'}}>
                    <div className='col-md-1'></div>
                    <div className='col-md-7' style={{textAlign: 'right'}}>
                        <img 
                            src={PetConverageNote} 
                            alt='Pet Exclusive' 
                            style={{
                                padding: '26px', 
                                backgroundColor: 'rgb(243, 214, 214)', 
                                borderRadius: '16px', 
                                marginTop: '8px',
                                // height: '100%',  
                                // width:'100%'
                            }}
                        />
                    </div>
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <img 
                                    src={PetExclusiveImage} 
                                    alt='Pet Exclusive' 
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-1'></div> */}
                </div>
            </section>
        </>
    );
};

export default PetConverage;

const covData = [
    {
        title: "CHI PHÍ Y TẾ CHO THÚ CƯNG",
        content: "Thú cưng được chẩn đoán và điều trị do bệnh tật hoặc thương tích theo quy định trong thời hạn bảo hiểm, FUBON sẽ thanh toán chi phí y tế cho thú cưng dựa trên chi phí y tế thực tế mà NĐBH đã chi trả, bao gồm: Chi phí điều trị ngoại trú; chi phí điều trị nội trú và Chi phí phẫu thuật (không bao gồm chi phí vận chuyển và chăm sóc).",
        image: PetExpenseImage1,
        direction: 'left'
    },
    {
        title: "TRÁCH NHIỆM BỒI THƯỜNG THIỆT HẠI DO THÚ CƯNG GÂY RA",
        content: "Trong thời hạn bảo hiểm, nếu thú cưng được bảo hiểm có hành vi tổn hại khiến bên thứ ba bị thương hoặc tử vong, dẫn đến phát sinh trách nhiệm pháp lý của NĐBH, thì FB sẽ thanh toán khoản bồi thường mà bên thứ ba yêu cầu NĐBH phải bồi thường.",
        image: PetExpenseImage2,
        direction: 'right'
    },
    {
        title: "CHI PHÍ QUẢNG CÁO TÌM KIẾM THÚ CƯNG THẤT LẠC",
        content: "Trong thời hạn bảo hiểm, nếu thú cưng bị thất lạc, Fubon sẽ chi trả chi phí quảng cáo tìm kiếm thú cưng trên các phương tiện truyền thông hoặc ấn phẩm để tìm thú cưng trong vòng 30 ngày kể từ ngày bị thất lạc (không bao gồm chi phí hậu tạ của NĐBH khi tìm lại thú cưng).",
        image: PetExpenseImage3,
        direction: 'left'
    },
    {
        title: "CHI PHÍ CHĂM SÓC THÚ CƯNG TRONG THỜI GIAN NĐBH NẰM VIỆN",
        content: "Nếu NĐBH phải nhập viện điều trị từ 3 ngày trở lên do bệnh tật hoặc thương tích do tai nạn trong thời hạn bảo hiểm nên không thể chăm sóc thú cưng trong thời gian nằm viện , nếu thú cưng được đưa vào Bệnh viện thú y hoặc cơ sở chăm sóc được thành lập hợp pháp, FUBON sẽ chi trả các chi phí nuôi thú cưng thực tế mà NĐBH phải chịu.",
        image: PetExpenseImage4,
        direction: 'right'
    },
    {
        title: "CHI PHÍ TANG LỄ CHO THÚ CƯNG",
        content: "Nếu thú cưng được bảo hiểm tử vong do bệnh tật hoặc thương tích trong thời hạn bảo hiểm, FUBON sẽ thanh toán chi phí tang lễ cho thú cưng theo chi phí thực tế mà NĐBH phải trả.",
        image: PetExpenseImage5,
        direction: 'left'
    },
]