import React from 'react';
import "./index.css";
import Title from './components/title/title';
import Item from './components/item/item';

const Documents = () => {
    return (
        <>
            <Title />
            <Item />
        </>
    );
};

export default Documents;
