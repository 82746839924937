import React, { useState, useEffect } from 'react'
import { redirect, useLoaderData } from 'react-router-dom';
import CommonHelper from '../../helpers/common';
import Authentication from '../../api/authApi';
import bgResetPass from "../../assets/images/bg-login.jpg";
import TextInput from '../../components/text-input';
import Loading from '../../components/loading';
import AlertBox from '../../components/alert';

export async function loader() {
    var _q = window.location.search;
    if (CommonHelper.is_not_empty(_q)) {
        _q = _q.substring(5);
    } else {
        _q = "/";
    }
    var obj = await Authentication.get_user();
    if (obj.code === 200) {
        window.location.href = _q;
    }
    return null;
}

const initial = {
    "user_name": "",
    "email": "",
    "pwd": ""
};

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

const steps = [{ code: 1 }, { code: 2 }]

function CustomerReset() {
    const [state, setState] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const [showLoading, setShowLoading] = useState(false);
    const [curr_step, setCurrStep] = useState(steps[0])
    const [stateError, setStateError] = useState({
        user_name: false,
        email: false,
    });
    const handle_change = (e) => {
        const { name, value } = e.target;
        setState((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }
    const validate_email_format = (e) => {
        const { name, value } = e.target;
        var flag = false;
        if (!CommonHelper.validate_email_format(value)) {
            setAlertData((prev) => ({
                ...prev,
                title: "Lỗi",
                content: "Email không đúng định dạng. Vui lòng kiểm tra lại!",
                is_show: true,
                variant: "danger"
            }))
            flag = true;
        }
        setStateError((prev) => ({
            ...prev,
            email: flag,
        }))
    };
    const handle_reset_pass = () => {
        var _req = { ...state };
        var flag = true;
        if (CommonHelper.is_empty(_req.user_name)) {
            flag = false;
            setAlertData((prev) => ({
                ...prev,
                is_show: true,
                variant: "danger",
                title: "Lỗi",
                content: "Tài khoản không tồn tại!"
            }))
        }
        if (CommonHelper.is_not_empty(_req.email) && !CommonHelper.validate_email_format(_req.email)) {
            flag = false;
        }
        if (flag) {
            setShowLoading(true);
            _req["pwd"] = "123Qwe!@#"
            var response = Authentication.reset_pwd(_req);
            response.then((res) => {
                setShowLoading(false);
                if (res.code === 200) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "success",
                        title: "Thông báo",
                        content: "Hệ thống đã reset mật khẩu. Vui lòng kiểm tra email!"
                    }))
                } else if (res.code === 700) {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "Tài khoản không tồn tại!"
                    }))
                } else {
                    setAlertData((prev) => ({
                        ...prev,
                        is_show: true,
                        variant: "danger",
                        title: "Lỗi",
                        content: "Reset mật khẩu thất bại. Vui lòng thử lại!"
                    }))
                }
            })
        } else {
            setAlertData((prev) => ({
                ...prev,
                is_show: true,
                variant: "danger",
                title: "Lỗi",
                content: "Vui lòng nhập đúng định Email đổi mật khẩu!"
            }))
        }
    }
    const handle_enter = (e) => {
        if (e.key === "Enter") {
            handle_reset_pass();
        }
    }
    return (
        <>
            <div className='row'>
                <div className='col-sm-6 mx-auto'>
                    <div className='mt15px'>
                        <h1 className='text-center'>Reset password</h1>
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <label className="form-label col-sm-3 required">Tài khoản đăng nhập</label>
                            <div className="col-sm-9">
                                <TextInput
                                    name="user_name"
                                    onChange={handle_change}
                                    value={state.user_name}
                                    onKeyDown={handle_enter}
                                    className={stateError.user_name ? "error" : ""} />
                            </div>
                        </div>
                        <div className='row mt15px'>
                            <label className="form-label col-sm-3">Email</label>
                            <div className="col-sm-9">
                                <TextInput
                                    name="email"
                                    onChange={handle_change}
                                    onBlur={validate_email_format}
                                    value={state.email}
                                    onKeyDown={handle_enter}
                                    className={stateError.email ? "error" : ""} />
                            </div>
                        </div>
                        <div className='row mt15px'>
                            <button className='btn btn-info btn-login mx-auto' style={{ color: "#fff", height: "60px", width: "200px" }} onClick={handle_reset_pass}>RESET</button>
                        </div>
                    </div>
                </div>
            </div>
            {showLoading && <Loading />}
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </>
    )
}

export default CustomerReset;