import React from 'react';
import "./title.css";

const Title = () => {
    return (
        <>
            <div className="title_container">
                <div className="title_body">
                    <div className="title_content">
                        <div className="title_block">
                            <h1 className="title_title">Tài liệu</h1>
                            <div className="title_description">Tài liệu triển khai Sản phẩm Bảo hiểm trên Môi trường mạng</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Title;