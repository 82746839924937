import React, { useState, useEffect } from 'react'
import { FaWindowClose } from "react-icons/fa";

const hospitals = [
    { name: "Forest International Animal Hospital", link: "https://linktr.ee/forestiah" },
    { name: "Pet Health", link: "https://pethealth.vn/blogs/benh-vien-thu-y-pethealth/lien-he" },
    { name: "Animal Doctors International (ADI)", link: "https://www.theanimaldoctors.org/vi/location/" },
    { name: "Asia Pet", link: "https://asiapet.vn/lien-he/" },
    { name: "Samyang Clinic", link: "https://benhvienthucungsamyang.com/" }
];

function PetPopup(props) {
    const { 
        onClose, 
        type, 
        ...other 
    } = props;
    const handle_close = (e) => {
        onClose(e);
    };
    const [showLinkHospital, setShowLinkHospital] = useState(false);

    const handleAnswer = (ans) => {
        if (ans === true) {
            setShowLinkHospital(true);
        } else {
            setShowLinkHospital(false);
        }
    };

    return (
        <div 
            className='bg-modal-gate' 
            style={{ textAlign: 'center' }}
        >
            <div className='opt-sm width__media-760'>
                <div className='container'>
                    <div className="row mt-4">
                        <div
                            className='col-sm-12'
                            style={{
                                backgroundColor: '#20c997',
                                paddingBottom: '8px',
                                color: 'white',
                                height: '32px',
                                marginBottom: '12px',
                                textAlign: 'center'
                            }}
                        >
                            <div className='row'>
                                <div 
                                    className='col-md-11' 
                                    style={{ height: '80px' }}
                                >
                                    <p>
                                        <b>
                                            <label className="form-label fs-5 font-semibold">
                                                KHẢO SÁT THÚ CƯNG
                                            </label>
                                        </b>
                                    </p>
                                </div>
                                <div className='col-md-1'>
                                    <FaWindowClose 
                                        style={{
                                            height: '24px', 
                                            width: '24px', 
                                            cursor: 'pointer'
                                        }} 
                                        onClick={() => handle_close()} 
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h4>
                                Thú cưng của bạn đã được gắn chip hay chưa?
                            </h4>
                        </div>
                    </div>
                    <hr />
                    {showLinkHospital && (
                        <div className='row' style={{ marginTop: '16px', marginBottom: '16px' }}>
                            <div className='col-md-12'>
                                <h4>Danh sách bệnh viện liên kết Fubon:</h4>
                                <table className="table table-bordered" style={{ margin: '0 auto', width: '80%', border: '2px solid rgb(32, 201, 151)' }}>
                                    <thead>
                                        <tr>
                                            <th>Tên Bệnh Viện</th>
                                            <th>Liên Kết</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {hospitals.map((hospital, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: 'left' }}>{hospital.name}</td>
                                                <td>
                                                    <a href={hospital.link} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
                                                        Xem
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    <div className='row'>
                        <div 
                            className='col-md-6' 
                            style={{ textAlign: 'center' }}
                        >
                            <div 
                                style={{ 
                                    marginTop: '16px', 
                                    marginBottom: '16px' 
                                }}
                            >
                                <button
                                    className="btn btn-primary"
                                    style={{
                                        height: '50px', 
                                        width: '100px',
                                        marginRight: '16px', 
                                        borderRadius: '0px',
                                        fontWeight: '700', 
                                        backgroundColor: '#20c997',
                                        border: 'none'
                                    }}
                                    onClick={() => handle_close()}
                                >
                                    CÓ
                                </button>
                            </div>
                        </div>
                        <div 
                            className='col-md-6' 
                            style={{ textAlign: 'center' }}
                        >
                            <div 
                                style={{ 
                                    marginTop: '16px', 
                                    marginBottom: '16px' 
                                }}
                            >
                                <button
                                    className="btn btn-outline-success"
                                    style={{ 
                                        height: '50px', 
                                        width: '100px', 
                                        marginRight: '16px', 
                                        borderRadius: '0px', 
                                        fontWeight: '700' 
                                    }}
                                    onClick={() => handleAnswer(true)}
                                >
                                    KHÔNG
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PetPopup;
