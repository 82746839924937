import api from "./api";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const Authentication = {
    get_token: async () => {
        var _token = await localforage.getItem("fb.online.token");
        if (!CommonHelper.is_not_empty(_token)) {
            var _url = "authentication/get_access_token?"
                + "user=" + process.env.REACT_APP_AUTH_USER
                + "&password=" + process.env.REACT_APP_AUTH_PWD;
            var res = await api.get(_url);
            if (res != null) {
                localforage.setItem('fb.online.token', res.token);
                /*var _url = "authentication/get_resources";
                var callApi = await api.get(_url);
                if (callApi.code === 200) {
                    if (callApi.data && callApi.data.length > 0) {
                        var resource = CommonHelper.convertArrayToObject(callApi.data);
                        localforage.setItem('fb.online.resources', resource);
                    }
                }*/
            }
        }
    },
    reset_token: async () => {
        localforage.setItem('fb.online.token', "");
        Authentication.get_token();
    },
    check_token_expired: async () => {
        var _token = await localforage.getItem("fb.online.token");
        if (CommonHelper.is_not_empty(_token)) {
            var _url = "authentication/check_token_expired";
            var callApi = await api.get(_url);
            if (callApi == null || callApi.status !== 200) {
                Authentication.reset_token();
            }
        } else {
            Authentication.get_token();
        }
    },
    get_resources: async () => {
        var _url = "authentication/get_resources";
        var callApi = await api.get(_url);
        if (callApi.code === 200) {
            if (callApi.data && callApi.data.length > 0) {
                var resource = CommonHelper.convertArrayToObject(callApi.data);
                localforage.setItem('fb.online.resources', resource);
            }
        }
    },
    login: async (data) => {
        var callApi = await api.post("online/login", data);
        if (callApi.code == 208) {
            localStorage.setItem('fb.online.session', callApi.data.session_id);
            localforage.setItem('fb.online.user', callApi.data.info);
            //console.log(callApi.data.info)
            return { code: callApi.code, msg: callApi.msg };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_user: async () => {
        //Authentication.get_token();
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.get("online/get_user?session_id=" + session_id);
            if (callApi.code == 200) {
                return { code: 200 };
            } else if (callApi.code === 401) {
                console.log(callApi);
            }
            CommonHelper.clear_storage_user();
            CommonHelper.clear_session();
            return { code: 506 };
        }
        return { code: 506 };
    },
    register: async (data) => {
        var callApi = await api.post("online/register", data);
        if (callApi.code == 205) {
            //localStorage.setItem('fb.online.session', callApi.data.session_id);
            //localforage.setItem('fb.online.user', callApi.data);
            return callApi;
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    logout: async () => {
        //Authentication.get_token();
        var session_id = CommonHelper.get_session_id();
        if (CommonHelper.is_not_empty(session_id)) {
            var callApi = await api.put("online/logout?session_id=" + session_id);
            if (callApi.code === 704) {
                return { code: callApi.code };
            } else if (callApi.code === 401) {
                console.log(callApi);
            }
            localforage.setItem('fb.online.user', null);
            CommonHelper.clear_session();
            return { code: 506 };
        }
        return { code: 506 };
    },
    agent_login: async (data) => {
        var callApi = await api.post("online/agent_login", data);
        if (callApi.code == 208) {
            localStorage.setItem('fb.online.session', callApi.data.session_id);
            localforage.setItem('fb.online.user', callApi.data.info);
            //console.log(callApi.data.info)
            return { code: callApi.code, msg: callApi.msg };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    handler_login: async (data) => {
        var callApi = await api.post("online/handler_login", data);
        if (callApi.code == 208) {
            localStorage.setItem('fb.online.session', callApi.data.session_id);
            localforage.setItem('fb.online.user', callApi.data.info);
            //console.log(callApi.data.info)
            return { code: callApi.code, msg: callApi.msg };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    agent_changepass: async (data) => {
        var session_id = CommonHelper.get_session_id();
        var callApi = await api.put("online/agent_changepass?session_id=" + session_id, data);
        if (callApi.code == 200) {
            console.log(callApi)
            return { code: callApi.code, msg: callApi.msg };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    handler_changepass: async (data) => {
        var session_id = CommonHelper.get_session_id();
        var callApi = await api.put("online/handler_changepass?session_id=" + session_id, data);
        if (callApi.code == 200) {
            //console.log(callApi.data.info)
            return { code: callApi.code, msg: callApi.msg };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    cms_login: async (data) => {
        var callApi = await api.post("online/cms_login", data);
        if (callApi.code == 208) {
            localStorage.setItem('fb.online.session', callApi.data.session_id);
            localforage.setItem('fb.online.user', callApi.data.info);
            //console.log(callApi.data.info)
            return { code: callApi.code, msg: callApi.msg };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    cms_changepass: async (data) => {
        var session_id = CommonHelper.get_session_id();
        var callApi = await api.put("online/cms_changepass?session_id=" + session_id, data);
        if (callApi.code == 200) {
            console.log(callApi)
            return { code: callApi.code, msg: callApi.msg };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    reset_pwd: async (req) => {
        var callApi = await api.put("online/reset_pwd_user/" + req.user_name, req);
        if (callApi.code == 200) {
            console.log(callApi)
            return { code: callApi.code, msg: callApi.msg };
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
};

export default Authentication;