import api from "./api";
import CommonHelper from "../helpers/common";
import axios from "axios";

const qrCodeApi = {
    get_employee_by_id: async (emp_code = 0) => {
        var callApi = await api.get(
            "qr/get_employee/" + emp_code
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_employee_active: async () => {
        var callApi = await api.get(
            "qr/get_employee_active"
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_employee_hashkey: async (hash) => {
        var callApi = await api.get(
            "qr/get_employee_hashkey/" + hash
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_employees: async (id = 0) => {
        var callApi = await api.get(
            "qr/get_employees/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_qr_by_id: async (id = 0) => {
        var callApi = await api.get(
            "qr/get_qr_by_id/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_namecard_image_by_url: async (url) => {
        const encodedUrl = encodeURIComponent(url);
        var temp = encodedUrl.replace('(', '%28').replace(')', '%29');
        var callApi = await api.get(`/qr/get_namecard_image_by_url?url=${temp}`, { responseType: 'blob' });
        console.log(callApi);
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_commons_by_type: async (model) => {
        var callApi = await api.get(
            "qr/get_commons_by_type?type=" + model
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_qrcodes: async (id = 0) => {
        var callApi = await api.get(
            "qr/get_qrcodes/" + id
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_employee_avatar: async (hash) => {
        var callApi = await api.get(
            "qr/get_employee_avatar/" + hash
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
    get_employee_ecard: async (hash) => {
        var callApi = await api.get(
            "qr/get_employee_ecard/" + hash
        );
        if (callApi.code === 401) {
            console.log(callApi);
        }
        return callApi;
    },
}

export default qrCodeApi;