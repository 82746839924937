import api from "./api";
import localforage from "localforage";
import CommonHelper from "../helpers/common";

const OnlineApi = {
    get_provinces: async (code) => {
        var callApi = await api.get("online/get_provinces");
        if (callApi.code == 200) {
            return callApi.data;
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return null;
    },
    get_districts: async (code, name) => {
        var _url = "online/get_districts?parent_code=" + code + "&name=" + name
        var callApi = await api.get(_url);
        if (callApi.code == 200) {
            return callApi.data;
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return null;
    },
    get_nations: async (code) => {
        var callApi = await api.get("online/get_nations?in_europe=" + code);
        console.log(callApi)
        if (callApi.code == 200) {
            return callApi.data;
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return null;
    },
    get_sob: async (code) => {
        var session_id = CommonHelper.get_session_id();
        var callApi = await api.get("online/get_sob?session_id=" + session_id);
        if (callApi.code == 200) {
            return callApi.data;
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return null;
    },
    get_agent: async (code) => {
        var callApi = await api.get("online/get_agent?code=" + code);
        if (callApi.code == 200) {
            return callApi.data;
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return null;
    },
    get_agent_by_handler: async () => {
        var session_id = CommonHelper.get_session_id();
        var callApi = await api.get("online/get_agent_by_handler?session_id=" + session_id);
        if (callApi.code == 200) {
            return callApi.data;
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return null;
    },
    get_ecommerce_document: async (code) => {
        var callApi = await api.get("online/get_ecommerce_document");
        if (callApi.code == 200) {
            return callApi;
        } else if (callApi.code === 401) {
            console.log(callApi);
        }
        return null;
    },
};

export default OnlineApi;