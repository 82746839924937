import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import PaymentGW from "../../../assets/images/payment-gateway.png";
import petApi from '../../../api/petApi';
import AlertBox from '../../../components/alert';
import { initialAlert } from '../util';
import { CgDanger } from "react-icons/cg";

function PetPayment(props) {
    const { 
        onClose, 
        onLoading, 
        model, 
        isShow, 
        show_payment_qr_code, 
        show_payment_gw, 
        show_order_no, 
        updateState, 
        ...other 
    } = props;
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [alertData, setAlertData] = useState(initialAlert);
    const [resPaymentStatus, setResPaymentStatus] = useState(null);
    const [showConfirmClose, setShowConfirmClose] = useState(false);

    useEffect(() => {
        if (isShow) {
            const intervalId = setInterval(() => {
                getPetPaymentStatus();
            }, 10000);

            return () => clearInterval(intervalId);
        }
    }, [isShow]);
    useEffect(() => {
        if (paymentStatus === 'success') {
            onClose(false, 0);
            updateState((prev) => ({
                ...prev,
                status: 2,
                cert_url: resPaymentStatus && resPaymentStatus.cert_url ? resPaymentStatus.cert_url : "",
            }));
        }
    }, [
        paymentStatus, 
        onClose
    ]);

    const getPetPaymentStatus = async () => {
        const res = await petApi.get_payment_status(show_order_no);
        if (res.code === 200 && res.data) {
            setPaymentStatus('success');
            setResPaymentStatus(res.data);
            setAlertData((prev) => ({
                ...prev,
                title: "Thông báo",
                content: "Thanh toán online thành công!",
                is_show: true,
                variant: "success"
            }))
        }
    };

    const handleConfirmClose = () => {
        setShowConfirmClose(true);
    };

    const handleCloseConfirmed = () => {
        setShowConfirmClose(false);
        onClose(false, 0);
    };

    return (
        <>
            <Modal
                show={isShow}
                onHide={handleConfirmClose}
                backdrop="static"
                // dialogClassName='modal-70w'
                keyboard={false}
                size='xl'
                centered
            >
                <Modal.Header 
                    style={{ 
                        backgroundColor: '#20c997', 
                        color: 'white' 
                    }} 
                    closeButton 
                    onClick={handleConfirmClose}
                >
                    <Modal.Title>
                        Thanh toán đơn bảo hiểm
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row'>
                            <div 
                                className='col-sm-6' 
                                style={{ borderRight: '3px solid #20c997' }}
                            >
                                <div className='row text-center'>
                                    <h4 style={{ fontSize: '24px' }}>
                                        Thanh toán bằng QR code
                                    </h4>
                                    <span style={{ fontStyle: 'italic' }}>
                                        (không mất phí chuyển khoản)
                                    </span>
                                </div>
                                <div 
                                    className='row' 
                                    style={{ 
                                        verticalAlign: 'middle', 
                                        textAlign: 'center' 
                                    }}
                                >
                                    <div className='col-md-12 mt-4'>
                                        <img
                                            src={`${show_payment_qr_code}`}
                                            alt="Thanh toán bằng QR Code"
                                            style={{ 
                                                height: '400px', 
                                                width: '400px' 
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='row text-center'>
                                            <h4 style={{ fontSize: '24px' }}>
                                                Thanh toán bằng cổng thanh toán 
                                            </h4>
                                            <span style={{ fontStyle: 'italic' }}>
                                                (mất phí chuyển khoản)
                                            </span>
                                        </div>
                                        <div 
                                            className='row mt-4' 
                                            style={{ 
                                                verticalAlign: 'middle', 
                                                textAlign: 'center' 
                                            }}
                                        >
                                            <div className='col-md-12 mt-4'>
                                                <a href={show_payment_gw}
                                                    // target='_blank'
                                                    // rel="noreferrer"
                                                    style={{ 
                                                        marginLeft: '4px', 
                                                        marginRight: '4px', 
                                                        textDecoration: 'none', 
                                                        fontWeight: '700', 
                                                        marginBottom: '16px' 
                                                    }} 
                                                >
                                                    Nhấp vào link này để thanh toán
                                                </a>
                                                <img
                                                    src={PaymentGW}
                                                    alt="Thanh toán bằng QR Code"
                                                    style={{ 
                                                        height: '300px', 
                                                        width: '300px', 
                                                        textAlign: 'center', 
                                                        alignItems: 'center' 
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{
                    background: 'linear-gradient(to right, #8B0000, #800080)',
                    color: 'white',
                    margin: '0px',
                    padding: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ fontSize: '30px', marginRight: '8px' }}>
                            <CgDanger />
                        </span>
                        <span style={{ fontSize: '22px', fontStyle: 'italic' }}>
                            Vui lòng không tắt trình duyệt khi đang thanh toán online!
                        </span>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showConfirmClose}
                onHide={() => setShowConfirmClose(false)}
                backdrop="static"
                keyboard={false}
                centered
                size='sm'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Xác nhận
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bạn có chắc chắn muốn đóng cửa sổ thanh toán?
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={() => setShowConfirmClose(false)}
                    >
                        Không
                    </Button>
                    <Button 
                        variant="danger" 
                        onClick={handleCloseConfirmed}
                    >
                        Có
                    </Button>
                </Modal.Footer>
            </Modal>
            <AlertBox 
                data={alertData} 
                onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} 
            />
        </>
    )
}

export default PetPayment;

