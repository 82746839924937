import React, { useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import vehicleApi from '../../api/vehicleApi';
import CommonHelper from '../../helpers/common';
import Loading from '../../components/loading';
import { FaCheckCircle } from 'react-icons/fa';
import { FaEye } from "react-icons/fa";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import DropdownButton from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import { IoReloadCircle } from "react-icons/io5";
import { MdRateReview } from "react-icons/md";
import { FaSearch } from "react-icons/fa";

function Order() {
    const [state, setState] = useState({
        order_no: "",
        order_status: "2",
        pol_no: "",
        from_date: "",
        to_date: "",
        reg_no: "",
        veh_type: "",
        cert_no: "",
        status: 0,
    });
    const [showLoading, setShowLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [{ fm_dt, to_dt }, setObject] = useState({
        fm_dt: null,
        to_dt: null,
    });
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;

    // State for filters
    const [statusFilter, setStatusFilter] = useState("");
    const [customerFilter, setCustomerFilter] = useState("");
    const [orderPrefixFilter, setOrderPrefixFilter] = useState("");
    const orderPrefixes = ["MBC", "MVC", "MBI", "PET", "PTI", "PAI"];
    const orderStatuses = [
        // {
        //     code: "0",
        //     name: "Tất cả",
        //     // bg_color: "green",
        //     bg_color: "",
        //     // color: "white",
        // },
        {
            code: "1",
            name: "Đơn mới khởi tạo",
        },
        {
            code: "2",
            name: "Đơn đã cấp",
        },
        {
            code: "3",
            name: "Đơn chờ thẩm định",
        },
        {
            code: "4",
            name: "Đơn đã thẩm định",
        },
        {
            code: "5",
            name: "Đơn đã hủy",
        },
        {
            code: "6",
            name: "Đơn chờ xác nhận",
        },
        {
            code: "7",
            name: "Đơn đã xác nhận",
        },
        /*{
            code: "10",
            name: "Đơn thanh toán bằng ACB",
            // bg_color: "blue",
            bg_color: "",
            // color: "white",
        },*/

    ];
    const [orderStatusFilter, setOrderStatusFilter] = useState("");

    const get_orders = (status) => {
        console.log("status", status);
        var _model = { ...state };
        var _url = "";
        if (CommonHelper.is_not_empty(_model.order_no)) {
            _url += "&order_no=" + _model.order_no;
        }
        if (CommonHelper.is_not_empty(_model.order_status)) {
            _url += "&order_status=" + _model.order_status;
        }
        if (CommonHelper.is_not_empty(_model.pol_no)) {
            _url += "&pol_no=" + _model.pol_no;
        }
        if (CommonHelper.is_not_empty(_model.customer)) {
            _url += "&customer=" + _model.customer;
        }
        if (CommonHelper.is_not_empty(_model.reg_no)) {
            _url += "&reg_no=" + _model.reg_no;
        }
        if (CommonHelper.is_not_empty(_model.veh_type)) {
            _url += "&veh_type=" + _model.veh_type;
        }
        // if (CommonHelper.is_not_empty(_model.from_date)) {
        //     _url += "&from_date=" + _model.from_date;
        // }
        // if (CommonHelper.is_not_empty(_model.to_date)) {
        //     _url += "&to_date=" + _model.to_date;
        // }
        // if (fm_dt) {
        //     _url += "&from_date=" + (CommonHelper.formatToYYYYMMDD(new Date(fm_dt)));
        // }
        // if (to_dt) {
        //     _url += "&to_date=" + (CommonHelper.formatToYYYYMMDD(new Date(to_dt)));
        // }
        if (fm_dt && to_dt) {
            _url += "&from_date=" + (CommonHelper.formatToYYYYMMDD(new Date(fm_dt)));
            _url += "&to_date=" + (CommonHelper.formatToYYYYMMDD(new Date(to_dt)));
        }
        console.log("STATUS", status && status != "undefined" && status != "" && status != "null", status)
        // if (status && status != "undefined" && status != "" && status != "null") {
        //     _url += "&status=" + status;
        // }
        if (status) {
            _url += "&status=" + status;
        }
        setShowLoading(true);
        var response = vehicleApi.get_orders(_url);
        response.then((res) => {
            setShowLoading(false);
            if (res.code === 200 && res.data !== null) {
                setOrders(res.data);
            } else {
                setOrders("");
            }
        });
    };

    useEffect(() => {
        get_orders();
    }, []);

    useEffect(() => {
        get_orders();
    }, [fm_dt, to_dt]);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentOrders = orders ? orders.slice(indexOfFirstItem, indexOfLastItem) : [];

    // const totalPages = orders ? Math.ceil(orders.length / itemsPerPage) : 0;

    // const filteredOrders = orders ? orders.filter(order => 
    //     order.order_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     order.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //     order.status_name.toLowerCase().includes(searchTerm.toLowerCase())
    // ) : [];

    // const filteredOrders = orders ? orders.filter(order =>
    //     (order.order_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         order.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         order.status_name.toLowerCase().includes(searchTerm.toLowerCase())) &&
    //     (statusFilter === "" || order.status_name === statusFilter) &&
    //     (customerFilter === "" || order.customer === customerFilter)
    // ) : [];

    const filteredOrders = orders ? orders.filter(order =>
        (order.order_no.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.customer.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.status_name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (statusFilter === "" || order.status_name === statusFilter) &&
        (customerFilter === "" || order.customer === customerFilter) &&
        (orderPrefixFilter === "" || order.order_no.startsWith(orderPrefixFilter)) // New filtering condition
    ) : [];

    const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const generatePageNumbers = () => {
        const pageNumbers = [];

        const startPage = Math.max(currentPage - 2, 1);
        const endPage = Math.min(startPage + 3, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setObject((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleStatusFilterChange = (status) => {
        setStatusFilter(status);
    };

    const handleCustomerFilterChange = (customer) => {
        setCustomerFilter(customer);
    };

    const generateOrderStatus = (order_status_code) => {
        let status_name = "";
        switch (order_status_code) {
            case 1:
                status_name = (<>
                    <span>
                        {"Đơn mới khởi tạo"}
                    </span>
                </>);
                break;
            case 2:
                status_name = (
                    <>
                        <FaCheckCircle
                            style={{
                                marginRight: '4px',
                                color: 'rgb(75, 174, 79)',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                            }}
                        />
                        <span style={{ color: 'rgb(75, 174, 79)', fontWeight: '700' }}>
                            {"Đơn đã cấp"}
                        </span>
                    </>);
                break;
            case 3:
                status_name = (<>
                    <span style={{ color: 'rgb(231 67 15)', fontWeight: '700' }}>
                        Chờ thẩm định
                    </span>
                    {/* <MdRateReview
                        style={{
                            marginLeft: '4px',
                            color: 'rgb(231 67 15)',
                            boxShadow: '0px 4px 8px rgb(231 67 15)',
                        }}
                    /> */}
                </>);
                break;
            case 4:
                status_name = (<>
                    <span>
                        {"Đơn đã thẩm định"}
                    </span>
                </>);
                break;
            case 5:
                status_name = (<>
                    <span>
                        {"Đơn đã hủy"}
                    </span>
                </>);
                break;
            case 6:
                status_name = (<>
                    <span>
                        {"Đơn chờ xác nhận"}
                    </span>
                </>);
                break;
            case 7:
                status_name = (
                    <>
                        {/* <FaCheckCircle
                            style={{
                                marginRight: '4px',
                                color: 'rgb(75, 174, 79)',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                            }}
                        /> */}
                        <span style={{ color: '#aaa', fontWeight: '700' }}>
                            {"Đơn đã xác nhận"}
                        </span>
                    </>);
                break;
            case 10:
                status_name = (<>
                    <span>
                        {"Đơn thanh toán bằng ACB"}
                    </span>
                </>);
                break;
            default:
                status_name = "";
        }
        return status_name;
    };

    return (
        <div className='row' style={{ background: "#fff" }}>
            <h1 className="text-center">
                {/* Quản lý Policy */}
                Quản lý Đơn
            </h1>
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex">
                    <input
                        type="text"
                        placeholder="Tìm kiếm đơn hàng..."
                        className="form-control"
                        style={{
                            width: '300px',
                            borderRadius: '0px',
                            marginRight: '16px'
                        }}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <select
                        className="form-control"
                        value={orderStatusFilter}
                        onChange={(e) => {
                            const selectedStatus = e.target.value;
                            setOrderStatusFilter(selectedStatus);
                            get_orders(selectedStatus);
                        }}
                        style={{
                            width: '300px',
                            borderRadius: '0px',
                            marginRight: '10px'
                        }}
                    >
                        <option value="0" style={{ fontWeight: '700' }}>
                            Tìm theo trạng thái Đơn
                        </option>
                        {orderStatuses.map((prefix, index) => (
                            <option
                                key={index}
                                value={prefix.code}
                                style={{
                                    backgroundColor: prefix.bg_color,
                                    color: prefix.color,
                                    fontWeight: '700'
                                }}
                            >
                                {prefix.name}
                            </option>
                        ))}
                    </select>
                    <select
                        className="form-control"
                        value={orderPrefixFilter}
                        onChange={(e) => setOrderPrefixFilter(e.target.value)}
                        style={{
                            width: '150px',
                            borderRadius: '0px',
                            marginRight: '10px'
                        }}
                    >
                        <option value="">
                            Chọn loại Đơn
                        </option>
                        {orderPrefixes.map((prefix, index) => (
                            <option key={index} value={prefix}>
                                {prefix}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="d-flex flex-nowrap align-items-center">
                    <label className="me-2">
                        Từ:
                    </label>
                    <input
                        type="date"
                        name="fm_dt"
                        value={fm_dt}
                        onChange={handleDateChange}
                        className="form-control"
                        style={{
                            minWidth: '150px',
                            cursor: 'text'
                        }}
                    />
                    <label className="ms-3 me-2">
                        Đến:
                    </label>
                    <input
                        type="date"
                        name="to_dt"
                        value={to_dt}
                        onChange={handleDateChange}
                        className="form-control"
                        style={{
                            minWidth: '150px',
                            cursor: 'text'
                        }}
                    />
                    {/* <button
                        className="btn btn-primary ms-3"
                        onClick={get_orders}
                        style={{ 
                            whiteSpace: 'nowrap', 
                            fontWeight: '700', 
                            borderRadius: '0px' 
                        }}
                        title='Tìm kiếm'
                    >
                        <FaSearch style={{ marginRight: '4px' }} />
                        Tìm kiếm
                    </button> */}
                </div>
            </div>
            <div className='col-sm-12 mt-2 mb-2'>
                <table className="table table-bordered table-striped table-responsive-sm">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: "5%" }} >
                                #
                            </th>
                            <th scope="col" style={{ width: "10%" }}>
                                Mã đơn hàng
                            </th>
                            <th scope="col">
                                Tên khách hàng
                            </th>
                            <th scope="col" style={{ width: "15%" }}>
                                Hiệu lực
                            </th>
                            {/* <th scope="col" style={{ width: "10%", textAlign: 'center' }}>
                                Thanh toán
                            </th> */}
                            <th scope="col" style={{ width: "10%", textAlign: 'center' }}>
                                Trạng thái
                            </th>
                            <th scope="col" style={{ width: "10%", textAlign: 'center' }}>
                                Tổng tiền
                            </th>
                            <th scope='col' style={{ width: "10%", textAlign: 'center' }}>
                                Thao tác
                            </th>
                        </tr>
                    </thead>
                    {(orders != null && orders.length > 0) ? (
                        <tbody>
                            {currentOrders.map((item, i) => (
                                <tr key={i} style={{ verticalAlign: 'middle' }}>
                                    <th scope='row'>
                                        {indexOfFirstItem + i + 1}
                                    </th>
                                    <td>
                                        {item.order_no}
                                    </td>
                                    <td>
                                        {item.customer}
                                    </td>
                                    <td>
                                        {CommonHelper.convertToDDMMYYYY(item.from_date) + " - " + CommonHelper.convertToDDMMYYYY(item.to_date)}
                                    </td>
                                    {/* <td style={{ textAlign: 'center' }}>
                                        {item.is_paid == true ? (
                                            <>
                                                <FaCheckCircle
                                                    style={{
                                                        marginRight: '4px',
                                                        color: 'rgb(75, 174, 79)',
                                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                                                    }} />
                                                <span style={{ color: 'rgb(75, 174, 79)', fontWeight: '700' }}>
                                                    Đã thanh toán
                                                </span>
                                            </>
                                        ) : <span style={{ color: 'rgb(231 67 15)', fontWeight: '700' }}>
                                                Chưa thanh toán
                                        </span>
                                        }
                                    </td> */}
                                    <td style={{ textAlign: 'center' }}>
                                        {generateOrderStatus(item.status)}
                                    </td>
                                    <td className='text-end'>
                                        {CommonHelper.format_currency_vnd_style(item.total)}
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            <div className='text-center flex-auto'>
                                                {item.is_paid === true ? (
                                                    <OverlayTrigger
                                                        placement="left"
                                                        overlay={<Tooltip>Xem</Tooltip>}
                                                    >
                                                        <a
                                                            title='Xem'
                                                            href={item.href}
                                                            style={{
                                                                fontSize: '18px',
                                                                color: 'orange'
                                                            }}
                                                        >
                                                            <FaIcons.FaEye />
                                                        </a>
                                                    </OverlayTrigger>
                                                ) : (item.status == 1 ?
                                                    <OverlayTrigger
                                                        placement="left"
                                                            overlay={<Tooltip>Chỉnh sửa</Tooltip>}>
                                                            <a title='Chỉnh sửa' href={item.href} style={{ fontSize: '18px' }}>
                                                            <FaIcons.FaPencilAlt />
                                                        </a>
                                                        </OverlayTrigger> : (<>
                                                            <OverlayTrigger placement="left" overlay={<Tooltip>Xem</Tooltip>}>
                                                                <a title='Xem' href={item.href} style={{
                                                                        fontSize: '18px',
                                                                        color: 'orange'
                                                                }}>
                                                                    <FaIcons.FaEye />
                                                                </a>
                                                        </OverlayTrigger>
                                                    </>)
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (<tbody>
                        <tr>
                            <td colSpan={8} className='text-center'>
                                    Không có dữ liệu
                                </td>
                            </tr>
                    </tbody>)}
                </table>
                <div className="d-flex justify-content-center mt-3">
                    <nav>
                        <ul className="pagination">
                            <li
                                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                                onClick={() => handlePageChange(currentPage - 1)}>
                                <a className="page-link" href="#">Trước</a>
                            </li>
                            {generatePageNumbers().map((number) => (
                                <li
                                    key={number}
                                    className={`page-item ${number === currentPage ? 'active' : ''}`}
                                    onClick={() => handlePageChange(number)}>
                                    <a className="page-link" href="#">{number}</a>
                                </li>
                            ))}
                            <li
                                className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                                onClick={() => handlePageChange(currentPage + 1)}>
                                <a className="page-link" href="#">Sau</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            {showLoading && <Loading />}
        </div>
    );
}

export default Order;
