import localforage from "localforage";

const CommonData = {
  language: [
    { id: "vi", val: "Tiếng Việt" },
    { id: "en", val: "English" },
    { id: "zh", val: "中國人" },
  ],
  resources: async () => {
    var _storage = await localforage.getItem("fb.resources");
    if (_storage != null) {
      return _storage;
    }
    return null;
  },
};
export default CommonData;
