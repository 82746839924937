import React, { useState, useEffect } from 'react';
import OnlineApi from "../../../../api/onlineApi";
import "./item.css";

const Item = () => {
    const [documents, setDocuments] = useState([]);
    const [showAll, setShowAll] = useState(false);
    useEffect(() => {
        fetchTopics();
    }, []);

    const fetchTopics = async () => {
        try {
            const response = await OnlineApi.get_ecommerce_document();
            if (response.code === 200) {
                setDocuments(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    return (
        <>
            <section className="doc_section">
                <div className="doc_container">
                    <div className="doc_body">
                        <div className="doc_item">
                            <div className="item_header">
                                <div className="item_header_blue" />
                                <div className="item_header_title">Tài liệu</div>
                            </div>
                            {documents.length === 0 ? (
                                <p style={{ textAlign: 'center' }}>Không có tài liệu</p>
                            ) : (
                                <>
                                    <div className="item_body">
                                        {
                                            (showAll ? documents : documents.slice(0, 4)).map((doc, index) => (
                                                <article key={index} className="item_body_article">
                                                    <div className="item_article_container">
                                                        <h4 className="item_article_title">
                                                            {doc.name}
                                                        </h4>
                                                    </div>
                                                    <div className="item_article_container_content">
                                                        <div className="item_article_body">
                                                            <a
                                                                href={doc.url ? doc.url : "#"}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="item_article_link"
                                                            >
                                                                <div className="item_article_icon">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={21}
                                                                        height={20}
                                                                        viewBox="0 0 21 20"
                                                                        fill="none"
                                                                        className="injected-svg"
                                                                        data-src="/icons/file.svg"
                                                                    >
                                                                        <g clipPath="url(#clip0_662_3705-28)">
                                                                            <path
                                                                                d="M3.5 19L3.5 -1.90735e-06L12.5088 -1.11978e-06L17.5 5.63924L17.5 19L3.5 19ZM4.93062 17.5736L16.0693 17.5736L16.0693 6.62187L11.4708 6.62187L11.4708 1.42616L4.93062 1.42616L4.93062 17.5736ZM15.1996 5.1956L12.9016 2.59895L12.9016 5.1956L15.1996 5.1956Z"
                                                                                fill="currentColor"
                                                                                stroke="#007DBC"
                                                                                strokeWidth="0.2"
                                                                            />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_662_3705-28">
                                                                                <rect
                                                                                    width={20}
                                                                                    height={20}
                                                                                    fill="white"
                                                                                    transform="translate(0.25)"
                                                                                />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </div>
                                                                <div className="caption">XEM CHI TIẾT</div>
                                                            </a>
                                                            <a
                                                                href={doc.url ? doc.url + "?download=true" : "#"}
                                                                download="report file"
                                                                className="item_article_link"
                                                            >
                                                                <div className="item_article_icon">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={21}
                                                                        height={20}
                                                                        viewBox="0 0 21 20"
                                                                        fill="none"
                                                                        className="injected-svg"
                                                                        data-src="/icons/download.svg"
                                                                    >
                                                                        <path
                                                                            d="M16.796 10.9609C16.8922 10.9609 16.971 11.0397 16.971 11.136V16.082V16.432V18.1583C16.971 18.2545 16.8923 18.3333 16.796 18.3333H15.0697H14.7197H5.00147H4.6515H2.92517C2.82898 18.3333 2.75012 18.2546 2.75012 18.1583V16.432V16.082L2.75 11.136C2.75 11.0398 2.82873 10.9609 2.92505 10.9609H4.65138C4.74757 10.9609 4.82643 11.0397 4.82643 11.136V16.082C4.82643 16.1782 4.90517 16.257 5.00148 16.257H14.7197C14.8159 16.257 14.8947 16.1783 14.8947 16.082L14.8946 11.136C14.8946 11.0398 14.9734 10.9609 15.0697 10.9609H16.796Z"
                                                                            fill="currentColor"
                                                                        />
                                                                        <path
                                                                            d="M14.4014 4.93519C14.3333 4.86708 14.2242 4.86927 14.1585 4.93959L11.0848 8.24649C11.0191 8.31705 10.9656 8.29581 10.9656 8.19962V1.0083C10.9656 0.912112 10.8869 0.833252 10.7906 0.833252H8.93143C8.83523 0.833252 8.75637 0.911987 8.75637 1.0083V8.20399C8.75637 8.30018 8.70279 8.3213 8.63723 8.25087L5.56067 4.94053C5.495 4.86997 5.38587 4.86802 5.31775 4.93613L4.00159 6.25255C3.93347 6.32066 3.93164 6.43407 3.99707 6.50438L9.73962 12.681C9.80505 12.7516 9.91235 12.7516 9.97802 12.681L15.7224 6.50376C15.7881 6.4332 15.7859 6.3198 15.7179 6.25192L14.4014 4.93519Z"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                </div>
                                                                <div className="item_article_caption">TẢI XUỐNG</div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </article>
                                            ))
                                        }
                                    </div>
                                    {documents.length > 4 && (
                                        <div className="item_footer">
                                            <button className="item_footer_button" onClick={toggleShowAll}>
                                                <div>{showAll ? "THU GỌN" : "XEM THÊM"}</div>
                                                <div className="item_footer_container">
                                                    <div className="item_footer_button_content">
                                                        <div className="item_footer_button_body">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={9}
                                                                height={6}
                                                                viewBox="0 0 9 6"
                                                                fill="none"
                                                                className="injected-svg"
                                                                data-src="/icons/arrow-paginate.svg"
                                                            >
                                                                <path
                                                                    d={showAll ? "M0.5 5.5L4.5 1.5L8.5 5.5" : "M8.5 0.5L4.5 4.5L0.5 0.5"}
                                                                    stroke="#00243C"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Item;
