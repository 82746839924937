import React, { useState, useEffect } from 'react';
import { FaPhoneAlt, FaUserPlus, FaCopy, FaCity } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { TbWorldWww } from "react-icons/tb";
import { FiLayout } from "react-icons/fi";
import qrCodeApi from '../../api/qrcodeApi';
import AlertBox from '../../components/alert';
import FubonLogo from "../../assets/images/fubon_pnt_logo.png";
import Fubon from "../../assets/images/fubon.png";
import { useLoaderData } from 'react-router-dom';
import CommonHelper from '../../helpers/common';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { IoPhonePortrait } from 'react-icons/io5';
import { MdAddToPhotos, MdLocationOn } from 'react-icons/md';
import "./index.css";
import employeeData from './data/index.json';

const initialAlert = {
    is_show: false,
    title: "",
    content: "",
    variant: "danger"
};

export async function loader({ params }) {
    var data = "";
    if (params.hash !== undefined && CommonHelper.is_not_empty(params.hash)) {
        const hash = params.hash;
        data = hash;
    }
    return data;
};

const QRCode = () => {
    const [showModal, setShowModal] = useState(false);
    const [employee, setEmployee] = useState(initial);
    const [alertData, setAlertData] = useState(initialAlert);
    const hash = useLoaderData();
    const [isEsignCard, setIsEsignCard] = useState(false);
    const [logo_namecard, setLogoNamecard] = useState(Fubon);
    const [img_ecard, setImgECard] = useState(Fubon);
    const [loading, setLoading] = useState(true);

    //#region FAKE CALL API
    const get_employee_hashkey = (hash) => {
        const employee = employeeData.find(emp => emp.HashKey == hash);
        if (employee) {
            return { code: 200, data: employee };
        } else {
            console.log("Employee not found");
            return { code: 404, data: null };
        }
    };
    //#endregion

    useEffect(() => {
        fetchEmployee();
    }, []);

    const fetchEmployee = async () => {
        const res = get_employee_hashkey(hash);
        if (res.code == 200) { // nếu tìm trong file json có thì dùng thông tin namecard này
            const _data = res.data;
            setEmployee(_data)
            setLoading(false);
        } else { // nếu tìm trong file json không có thì gọi api để set data
            const response = qrCodeApi.get_employee_hashkey(hash);
            response.then((res) => {
                if (res.code == 200) {
                    const _data = JSON.parse(res.data)[0];
                    setEmployee(_data)
                }
                setLoading(false);
            })
        }
        setLoading(false);
        // lấy 2 cái hình thì gọi API
        get_employee_avatar(hash);
        get_employee_ecard(hash);
    };
    const get_employee_avatar = (key) => {
        const employee = employeeData.find(emp => emp.HashKey == key);
        if (employee) {
            setLogoNamecard("https://online.fubonins.com.vn/documents" + employee.ImagePath);
            //return { code: 200, data: employee };
        }
        /*const response = qrCodeApi.get_employee_avatar(key);
        response.then((res) => {
            if (res.code == 200) {
                setLogoNamecard(res.data);
            }
            setLoading(false);
        })*/
    }
    const get_employee_ecard = async (key) => {
        const employee = employeeData.find(emp => emp.HashKey == key);
        if (employee) {
            setImgECard("https://online.fubonins.com.vn/documents" + employee.Setting_1);
            //return { code: 200, data: employee };
        }
        /*const response = qrCodeApi.get_employee_ecard(key);
        response.then((res) => {
            if (res.code == 200) {
                setImgECard(res.data);
            }
            setLoading(false);
        })*/
    }
    const handleOpenModal = () => {
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };
    const handleAddToContacts = async () => {
        if (window.confirm("Do you want to add this contact to your contacts?")) {
            const res = await qrCodeApi.get_commons_by_type("NAMECARD");
            const namecard_template = res.data.flexValue1 ?? namecard_default;
            const _data = {
                EMP_FULL_NAME: employee?.EMP_FULL_NAME || "No Name",//Họ và tên không dấu - tiếng Anh
                EMP_FULLNAME_TW: employee?.EMP_FULLNAME_TW || "No Name",//Họ và tên tiếng Hoa
                EMP_FULLNAME_VN: employee?.EMP_FULLNAME_VN || "No Name",//Họ và tên tiếng Việt
                EMP_REMARKS_1: employee?.EMP_REMARKS_1 || "No Remark",//Chức vụ tiếng Việt
                EMP_REMARKS_2: employee?.EMP_REMARKS_2 || "No Remark",//Chức vụ tiếng Anh
                EMP_REMARKS_3: employee?.EMP_REMARKS_3 || "No Remark",//Chức vụ tiếng Hoa
                EMP_REMARKS_4: employee?.EMP_REMARKS_4 || "No Remark",//Mode ngôn ngữ
                EMP_POSITION_1: employee?.EMP_POSITION_1 || "No Title",//Chi nhánh - phòng ban tiếng Việt
                EMP_POSITION_2: employee?.EMP_POSITION_2 || "No Title",//Chi nhánh - phòng ban tiếng Anh
                EMP_POSITION_3: employee?.EMP_POSITION_3 || "No Title",//Chi nhánh - phòng ban tiếng Hoa
                EMP_POSITION_4: employee?.EMP_POSITION_4 || "No Title",
                EMP_MOBILE_PHONE: employee?.EMP_MOBILE_PHONE ? formatTelephoneNumber(employee.EMP_MOBILE_PHONE) : "",//Số điện thoại
                EMP_EMAIL: employee?.EMP_EMAIL || "No Email",//Email
                REV: new Date().toISOString(),// ngày tạo
                // ORG: "越南富邦產物保險責任有限公司 - Fubon Insurance Vietnam Co., Ltd.",//Tên công ty
                ORG: "Fubon Insurance Vietnam Co., Ltd.",//Tên công ty
                ADDRESS: employee?.EMP_ADDRESS || "No Address",// địa chỉ
            }
            const vCardData = `${namecard_template.replaceAll("\\n", "\n")
                .replaceAll("$_EMP_FULL_NAME", _data.EMP_FULL_NAME)//Họ và tên không dấu - tiếng Anh
                .replaceAll("$_EMP_FULLNAME_TW", _data.EMP_FULLNAME_TW)//Họ và tên tiếng Hoa
                .replaceAll("$_EMP_FULLNAME_VN", _data.EMP_FULLNAME_VN)//Họ và tên tiếng Việt
                .replaceAll("$_EMP_REMARKS_1", _data.EMP_REMARKS_1)//Chức vụ tiếng Việt
                .replaceAll("$_EMP_REMARKS_2", _data.EMP_REMARKS_2)//Chức vụ tiếng Anh
                .replaceAll("$_EMP_REMARKS_3", _data.EMP_REMARKS_3)//Chức vụ tiếng Hoa
                .replaceAll("$_EMP_REMARKS_4", _data.EMP_REMARKS_4)//Mode ngôn ngữ
                .replaceAll("$_EMP_POSITION_1", _data.EMP_POSITION_1)//Chi nhánh - phòng ban tiếng Việt
                .replaceAll("$_EMP_POSITION_2", _data.EMP_POSITION_2)//Chi nhánh - phòng ban tiếng Anh
                .replaceAll("$_EMP_POSITION_3", _data.EMP_POSITION_3)//Chi nhánh - phòng ban tiếng Hoa
                .replaceAll("$_EMP_POSITION_4", _data.EMP_POSITION_4)
                .replaceAll("$_EMP_MOBILE_PHONE", _data.EMP_MOBILE_PHONE)//Số điện thoại
                .replaceAll("$_EMP_EMAIL", _data.EMP_EMAIL)//Email
                .replaceAll("$_ORG", _data.ORG)//Tên công ty
                .replaceAll("$_ADDRESS", _data.ADDRESS)// địa chỉ
                .replaceAll("$_REV", _data.REV)// ngày tạo
                }`;
            const blob = new Blob([vCardData], { type: 'text/vcard' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${employee?.EMP_FULL_NAME || "contact"}.vcf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Clean up the URL object
        }
    };
    const formatPhoneNumber = (internationalNumber) => {
        internationalNumber = internationalNumber.replace(/\./g, '');
        if (internationalNumber.startsWith("+84")) {
            return '0' + internationalNumber.slice(3);
        }
        return internationalNumber;
    }
    function formatTelephoneNumber(phoneNumber) {
        phoneNumber = phoneNumber.replace(/[^\d]/g, '');
        return phoneNumber;
    }
    function formatTelephoneNumberText(phoneNumber) {
        if (phoneNumber.startsWith("84")) {
            phoneNumber = '+' + phoneNumber;
        }
        return phoneNumber;
    }
    const handleReload = () => {
        //window.location.reload();
        fetchEmployee();
    };
    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Full height of the viewport
                    textAlign: 'center'
                }}
                onClick={handleReload}
            >
                <Spinner style={{ marginRight: '6px' }} />
                Loading...
            </div>
        );
    }

    return (
        <>
            {
                !isEsignCard ? (
                    <>
                        <div className="container-fluid body" style={{ backgroundColor: '#f7f7f7' }}>
                            <div className="row">
                                <div className="qr-code px-0 col-md-6 col-sm-12 offset-md-3">
                                    <div className="item-header">
                                        <div className="px-0 w-100">
                                            <div className="text-center qr-card-wrapper">
                                                <div className="p-3 img-wrap">
                                                    <img
                                                        style={{ border: "4px solid white", width: '250px', height: '250px', borderRadius: '50%', objectFit: 'cover' }}
                                                        className="img-body"
                                                        src={logo_namecard}
                                                        alt='Logo Namecard'
                                                    />
                                                </div>
                                                <div className="p-4">
                                                    <h6 style={{ wordWrap: "break-word" }} className="text-white qr-card-title">
                                                        {
                                                            employee && (
                                                                (!CommonHelper.is_empty(employee.EMP_REMARKS_4) && (employee.EMP_REMARKS_4 == "VA")) ? (<>
                                                                    <p style={{ fontSize: '14px' }}>
                                                                        <span>{(employee.EMP_FULLNAME_VN ?? "")}</span>
                                                                        <sup style={{ fontSize: '10px', color: 'red', marginLeft: '4px' }}>{(employee.EMP_POSITION_4 ?? "")}</sup>
                                                                        <span>{(" / " + employee.EMP_FULL_NAME ?? "")}</span>
                                                                        <sup style={{ fontSize: '10px', color: 'red', marginLeft: '4px' }}>{(employee.EMP_POSITION_4 ?? "")}</sup>
                                                                    </p>
                                                                    <p style={{ fontSize: '14px' }}>
                                                                        {(employee.EMP_POSITION_1 ?? "")}{(" / " + employee.EMP_POSITION_2 ?? "")}
                                                                    </p>
                                                                    <p style={{ fontSize: '14px' }}>
                                                                        {(employee.EMP_REMARKS_1 ?? "")}{(" / " + employee.EMP_REMARKS_2 ?? "")}
                                                                    </p>
                                                                </>) : (<>
                                                                        <p style={{ fontSize: '14px' }}>
                                                                            {(employee.EMP_FULLNAME_TW ?? "")}{(" / " + employee.EMP_FULL_NAME ?? "")}
                                                                        </p>
                                                                        <p style={{ fontSize: '14px' }}>
                                                                            {(employee.EMP_POSITION_3 ?? "")}{(" / " + employee.EMP_POSITION_2 ?? "")}
                                                                        </p>
                                                                        <p style={{ fontSize: '14px' }}>
                                                                            {(employee.EMP_REMARKS_3 ?? "")}{(" / " + employee.EMP_REMARKS_2 ?? "")}
                                                                        </p>
                                                                    </>)
                                                            )
                                                        }
                                                    </h6>
                                                </div>
                                                <div className="fbcard-functions p-3">
                                                    <div className="fbcard-functions-wrapper">
                                                        <a
                                                            href={employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) && "tel:" + formatTelephoneNumber(employee.EMP_MOBILE_PHONE)}
                                                            className=" bg-solid "
                                                            style={{ backgroundColor: '#0dcaf0' }}
                                                        >
                                                            <FaPhoneAlt style={{ fontSize: '25px' }} />
                                                            <small className="dynamicText">Phone</small>
                                                        </a>
                                                        <a
                                                            href={employee && !CommonHelper.is_empty(employee.EMP_EMAIL) && "mailto:" + employee.EMP_EMAIL}
                                                            target="_newEmail"
                                                            className=" bg-solid "
                                                            style={{ backgroundColor: '#0dcaf0' }}
                                                        >
                                                            <AiOutlineMail style={{ fontSize: '25px' }} />
                                                            <small className="dynamicText">Email</small>
                                                        </a>
                                                        <a
                                                            href="https://www.fubonins.com.vn/"
                                                            target="_blank"
                                                            className="border-right-0  bg-solid "
                                                            rel="noreferrer"
                                                            style={{ backgroundColor: '#0dcaf0' }}
                                                        >
                                                            <TbWorldWww style={{ fontSize: '25px' }} />
                                                            <small className="dynamicText">Website</small>
                                                        </a>
                                                        <a
                                                            href="#"
                                                            className="border-right-0  bg-solid "
                                                            rel="noreferrer"
                                                            style={{ backgroundColor: '#0dcaf0' }}
                                                            onClick={() => setIsEsignCard(!isEsignCard)}
                                                        >
                                                            <FiLayout style={{ fontSize: '25px' }} />
                                                            <small className="dynamicText">Ecard</small>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <NamecardInfo
                                        employee={employee}
                                        formatPhoneNumber={formatPhoneNumber}
                                        formatTelephoneNumber={formatTelephoneNumber}
                                        formatTelephoneNumberText={formatTelephoneNumberText}
                                        data={img_ecard} />
                                    <div className="">
                                        <button
                                            className="btn btn-block btn-lg btn-fbcard d-none d-sm-block bg-solid"
                                            style={{ width: '100%' }}
                                            onClick={() => handleOpenModal()}
                                        >
                                            <FaCopy style={{ fontSize: '25px', marginRight: '8px' }} />
                                            <span>Copy Card</span>
                                        </button>
                                        <button className="btn btn-add btn-fbcard-round d-sm-none bg-solid" onClick={handleAddToContacts}>
                                            <FaUserPlus style={{ fontSize: '20px', marginRight: '8px', float: 'left', marginTop: '12px' }} />
                                            <span>Add to contacts</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <footer style={{ textAlign: 'center', height: '120px', backgroundColor: '#f7f7f7' }}>
                            <div className="mx-auto">Powered by Fubon Insurance</div>
                        </footer>
                    </>
                ) : (
                    <>
                        <ECard
                                employee={employee}
                                handleOpenModal={handleOpenModal}
                                handleAddToContacts={handleAddToContacts}
                                formatPhoneNumber={formatPhoneNumber}
                                data={img_ecard} />
                        <NamecardFooter
                            isEsignCard={isEsignCard}
                            setIsEsignCard={setIsEsignCard}
                        />
                    </>
                )}
            {showModal && (
                <DownloadInfoModal
                    showModal={showModal}
                    handleClose={handleModalClose}
                    data={window.location.href}
                />
            )}
            <AlertBox data={alertData} onChange={(e) => { setAlertData((prev) => ({ ...prev, is_show: e })) }} />
        </>
        // )
    );
};

export default QRCode;

//===========================
//#region NamecardFooter
const NamecardFooter = (props) => {
    const { isEsignCard, setIsEsignCard, ...other } = props;
    return (
        <>
            <footer style={{ textAlign: 'center', height: '120px', backgroundColor: '#f7f7f7' }}>
                <div className="mx-auto mt-2">Powered by Fubon Insurance</div>
                <div className='row mt15px'>
                    <div
                        className='col-md-12'
                        style={{
                            cursor: 'pointer',
                            fontWeight: '700',
                            fontSize: '20px',
                            textDecoration: 'underline',
                            color: 'blue',
                        }}
                        onClick={() => setIsEsignCard(!isEsignCard)}
                    >
                        <p style={{ backgroundColor: '#ccc', borderRadius: '0px', padding: '8px' }}>BACK TO</p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export { NamecardFooter };
//#endregion

//===========================
//#region NamecardInfo
const NamecardInfo = (props) => {
    const { employee, formatPhoneNumber, formatTelephoneNumber, formatTelephoneNumberText, ...other } = props;
    return (
        <>
            <div className="item-body mt-3 p-3">
                <div className="details-row w-100">
                    <a className="details-info" href='#'>
                        <FaCity style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div >
                            <small style={{ color: '#b3b4bb' }}>Company</small>
                            <div>
                                <p style={{ fontSize: '16px', marginBottom: '2px' }}>越南富邦產物保險責任有限公司</p>
                                <p style={{ fontSize: '16px' }}>Fubon Insurance Vietnam Co., Ltd. </p>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a className="details-info"
                        // href={"tel:02839435678"}
                        href={employee && !CommonHelper.is_empty(employee.EMP_EXT_3) && "tel:" + formatTelephoneNumber(employee.EMP_EXT_3)}
                    >
                        <FaPhoneAlt style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div >
                            <small style={{ color: '#b3b4bb' }}>Telephone</small>
                            <div style={{ fontSize: '16px' }}>
                                {/* {"84-28-3943 5678"} */}
                                <span style={{ marginRight: '32px' }}>{employee && !CommonHelper.is_empty(employee.EMP_EXT_3) && "Tel: " + employee.EMP_EXT_3}</span>
                                <span>{employee && !CommonHelper.is_empty(employee.EMP_EXT_1) && "Ext: " + employee.EMP_EXT_1}</span>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a
                        className="details-info"
                        href={employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) && "tel:" + formatTelephoneNumber(employee.EMP_MOBILE_PHONE)}
                    >
                        <IoPhonePortrait style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div>
                            <small style={{ color: '#b3b4bb' }}>Mobile Phone</small>
                            <div style={{ fontSize: '16px' }}>
                                {employee && !CommonHelper.is_empty(employee.EMP_MOBILE_PHONE) && employee.EMP_MOBILE_PHONE}
                            </div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a className="details-info" href={employee && !CommonHelper.is_empty(employee.EMP_EMAIL) && "mailto:" + employee.EMP_EMAIL}>
                        <AiOutlineMail style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div>
                            <small style={{ color: '#b3b4bb' }}>Email</small>
                            <div className="text-truncate" style={{ fontSize: '16px' }}>
                                {employee && !CommonHelper.is_empty(employee.EMP_EMAIL) && employee.EMP_EMAIL}
                            </div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a
                        className="details-info"
                        href={`https://www.google.com/maps/dir/?api=1&destination=${employee && !CommonHelper.is_empty(employee.EMP_ADDRESS) && employee.EMP_ADDRESS}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <MdLocationOn style={{ fontSize: '40px', marginRight: '1rem', color: '#089a8d' }} />
                        <div>
                            <small style={{ color: '#b3b4bb' }}>Address</small>
                            <div style={{ fontSize: '16px' }}>{employee && !CommonHelper.is_empty(employee.EMP_ADDRESS) && employee.EMP_ADDRESS}</div>
                        </div>
                    </a>
                </div>
                <div className="details-row w-100">
                    <a className="details-info" href="https://www.fubonins.com.vn/gioi-thieu">
                        <TbWorldWww style={{ fontSize: '25px', marginRight: '1rem', color: '#089a8d' }} />
                        <div>
                            <small style={{ color: '#b3b4bb' }}>Website</small>
                            <div>https://www.fubonins.com.vn/</div>
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
};

export { NamecardInfo };
//#endregion

//=============================
//#region  
const DownloadInfoModal = ({ showModal, handleClose, data }) => {

    const copyToClipboard = () => {
        const qrCodeValue = document.getElementsByName('qrCode')[0].value;
        navigator.clipboard.writeText(qrCodeValue).then(() => {
            alert('QR Code link copied to clipboard');
        }).catch(err => {
            alert('Failed to copy text: ', err);
        });
    };

    return (
        <>
            <Modal
                show={showModal}
                dialogClassName="custom-modal"
                style={{ marginTop: "200px" }}
                backdrop="static"
                onHide={handleClose}
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title as="h3">Copy Fubon Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Copy this FubonCard.</Form.Label>
                        </Form.Group>
                        <Form.Group style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', marginTop: '16px', marginBottom: '16px' }}>
                            <MdAddToPhotos style={{ fontSize: '40px', marginRight: '8px' }} />
                            <Form.Control type="text" name="qrCode" value={data} />
                            <Button
                                onClick={copyToClipboard}
                                id="myQRCode"
                                style={{ marginLeft: '16px', marginRight: '16px' }}
                            >
                                Copy
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export { DownloadInfoModal };
//#endregion

//=====================================
//#region ECard
const ECard = (props) => {
    const { employee, handleOpenModal, handleAddToContacts, formatPhoneNumber, data, ...other } = props;
    return (
        <>
            {
                (CommonHelper.is_not_empty(data) !== "") ? (
                    <>
                        <div style={{ width: '100%', marginTop: '200px' }}>
                            <img
                                src={data}
                                alt="Ecard"
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </div>
                    </>
                ) : (<p>No Ecard setup!</p>)
            }
        </>
    );
};

export { ECard };
//#endregion

export const initial = {
    HashKey: "",
    EMP_ID: "",
    EMP_CODE: "",
    EMP_FULL_NAME: "",
    EMP_FULLNAME_VN: "",
    EMP_FULLNAME_TW: "",
    EMP_REMARKS_1: "",
    EMP_REMARKS_2: "",
    EMP_REMARKS_3: "",
    EMP_REMARKS_4: "",
    EMP_MOBILE_PHONE: "",
    EMP_EMAIL: "",
    EMP_POSITION_1: "",
    EMP_POSITION_2: "",
    EMP_POSITION_3: "",
    EMP_POSITION_4: "",
    EMP_DEPT_CODE: "",
    EMP_ADDRESS: "",
    EMP_TITLE: "",
    EMP_LEVEL: "",
    EMP_GENDER: "",
    EMP_STATUS: "",
    EMP_EXT_1: "",
    EMP_EXT_2: "",
    EMP_EXT_3: "",
    Image: "",
    ImagePath: "",
    IsActive: "",
    CreatedDate: "",
    ModifiedDate: "",
    Setting_1: "",
    Setting_2: "",
    Setting_3: "",
    Setting_4: "",
    Setting_5: "",
};

export const namecard_default = "BEGIN:VCARD\nVERSION:3.0\nFN:$_EMP_FULL_NAME\nN:$_EMP_FULL_NAME\nORG:$_ORG\nTITLE:$_EMP_POSITION_2\nTEL;TYPE=CELL:$_EMP_MOBILE_PHONE\nEMAIL:$_EMP_EMAIL\nADR;TYPE=WORK:;;$_ADDRESS\nREV:$_REV\nEND:VCARD";